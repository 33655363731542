<template>
    <div>
        <loading-card v-if="affaire_type == null || saving || deleting">
            <template v-if="saving">Enregistrement en cours ...</template>
            <template v-else-if="deleting">Suppression en cours ...</template>
            <template v-else>Chargement du type d'affaire ...</template>
        </loading-card>
        <b-card title="Type Affaire" class="card-other" v-else >
            <validation-observer ref="simpleRules">
                <b-form>
                    <b-row>
                        <b-col lg="6" md="12">
                            <b-form-group label="Code" label-for="code">
                                <validation-provider #default="{ errors }" name="code" rules="required">
                                    <b-form-input 
                                        v-model="code"
                                        id="code"
                                        placeholder="Code"
                                        :state="errors.length > 0 ? false : null" 
                                    />
                                    <small class="text-danger" v-if="errors.length > 0">{{errors[0]}}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>

                        <b-col lg="6" md="12">
                            <b-form-group label="Libellé" label-for="libelle">
                                <validation-provider #default="{ errors }" name="libelle" rules="required">
                                    <b-form-input 
                                        v-model="libelle"
                                        id="libelle"
                                        placeholder="Libellé"
                                        :state="errors.length > 0 ? false : null"
                                    />
                                    <small class="text-danger" v-if="errors.length > 0">{{errors[0]}}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col sm="12" lg="6" md="8">
                            <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-primary" to="/affaire_types/list" class="mr-1">
                                <feather-icon icon="ListIcon" class="mr-50"/>
                                <span class="align-middle">Revenir à la liste</span>
                            </b-button>

                            <b-button variant="gradient-success" type="submit" @click.prevent="validationForm">
                                <feather-icon icon="CheckIcon" class="mr-50"/>
                                <span class="align-middle">{{creating ? "Ajouter" : "Enregistrer"}}</span>
                            </b-button>
                        </b-col>

                        <b-col sm="12" lg="6" md="4" :class="deleteClass" v-if="!creating">
                            <b-button variant="gradient-danger" @click="confirmDeleteAffaireType(affaire_type.id, affaire_type.libelle, goBack)">
                                <feather-icon icon="TrashIcon" class="mr-50"/>
                                <span class="align-middle">Supprimer</span>
                            </b-button>
                        </b-col>
                    </b-row>
                </b-form>
            </validation-observer>
        </b-card>
    </div>
</template>

<script>
    import LoadingCard from "@/views/GDLoadingCard"
    import miscApi from "@/api/miscApi"
    import {computedFactory} from "@/services/computedFactory"
    import { computed, ref } from '@vue/composition-api'
    import { confirmDeleteAffaireType, deleteAffaireType } from "@/views/setups/affaire_type"

    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import { required } from '@validations'

    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
    
    export default {
        components: {
            LoadingCard,
            ValidationProvider,
            ValidationObserver,
            ToastificationContent
        },

        data() {
            return {
                saving: false,
                deleting: false,
            }
        },

        beforeMount() {
            if(this.$route.params.hasOwnProperty("affaireTypeId"))
                this.fetchAffaireType();
        },

        setup(props, {root}) {
            const currentBreakPoint = computed(() => root.$store.getters['app/currentBreakPoint'])
            const expectedFields = ["code", "libelle"];
            let affaire_typeBase = null;
            let creatingBase = !root.$route.params.hasOwnProperty("affaireTypeId");
            if(creatingBase)
                affaire_typeBase = expectedFields.reduce((accumulator, field) => ({...accumulator, [field]: ""}), {});

            const affaire_type = ref(affaire_typeBase);
            const creating = ref(creatingBase)
            return {
                affaire_type,
                creating,
                expectedFields,
                ...computedFactory(expectedFields, "affaire_type"),
                currentBreakPoint,
                required,
                confirmDeleteAffaireType,
                deleteAffaireType
            }
        },

        computed: {
            deleteClass() {
                return (this.currentBreakPoint == "sm" || this.currentBreakPoint == "xs") ? "mt-1" : "text-right";
            }
        },

        methods: {
            goBack() {
                // ! ATTENTION => fonctionne dans le cas retour à la liste. si on utilise le composant AffaireType à un autre endroit
                // ! il faudra surement revoir la logique. a garder en tête.
                console.log("go back")
                this.$router.go(-1)
            },

            async fetchAffaireType() {
                this.affaire_type = await miscApi.getAffaireType(this.$route.params.affaireTypeId);
            },

            async validationForm() {
                let res = await this.$refs.simpleRules.validate()
                if(res)
                    this.save();
            },
            
            async save() {
                this.saving = true;
                let data = this.expectedFields.filter(field => this[field] != this.affaire_type[field]).reduce((accumulator, currentValue) => ({...accumulator, [currentValue]: this[currentValue]}), {});
                let callApi = this.creating ? () => miscApi.addAffaireType(data) : () => miscApi.patchAffaireType(this.affaire_type.id, data)
                try {
                    if(Object.entries(data).length != 0)
                        await callApi();
                    this.showToast("success", this.creating ? "AffaireType créé" : "AffaireType mis à jour", "CheckIcon")
                    this.$router.go(-1);
                } catch(e) {
                    console.log("e", e)
                    this.showToast("danger", "Une erreur s'est produite", "AlertCircleIcon")
                }
                finally {
                    this.saving = false;
                }
            },

            // TODO déplacer dans service, en faire une méthode générique
            showToast(variant, text, icon) {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        icon,
                        text,
                        variant
                    },
                }, {position: "bottom-right", timeout: 2500})
            },
        }

    }
</script>