import miscApi from "@/api/miscApi"

export async function confirmDeleteAffaireType(affaire_typeId, affaire_typeNom, deleteCallback = null) {
    let {isConfirmed} = await this.$swal({
        title: 'Supprimer Type Affaire',
        text: `Etes-vous sûr de vouloir supprimer le type d'affaire ${affaire_typeNom}`,
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Confirmer',
        cancelButtonText: "Annuler",
        customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
    })

    if(isConfirmed)
        await this.deleteAffaireType(affaire_typeId, deleteCallback);
}

export async function deleteAffaireType(affaire_typeId, deleteCallback = null) {
    if(this.hasOwnProperty("deleting"))
        this.deleting = true;
    try {
        await miscApi.deleteAffaireType(affaire_typeId);
        this.showToast("success", "AffaireType supprimé", "CheckIcon")
        if(deleteCallback != null && typeof deleteCallback === "function")
            deleteCallback(affaire_typeId);
        
    } catch(e) {
        console.log("e", e)
        this.showToast("danger", "Une erreur s'est produite", "AlertCircleIcon")
    }
    finally {
        if(this.hasOwnProperty("deleting"))
            this.deleting = false;
    }
}